import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useWeb3 } from "../Web3Context";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

const MainStage = () => {
    const { user, open } = useWeb3();
    const [leaderboard, setLeaderboard] = useState([]);
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [tutorialIndex, setTutorialIndex] = useState(0);
    const tutorialStates = [
        {
            image: "onboading-1.jpg",
            header: "Welcome to the Lympid Community!",
            text: "Climb to the top of the quests leaderboard. Find out how.",
            text2: "Get points by completing quests in our Studio, or on our Zealy page. Zealy points are automatically summed to your total points.",
            buttons: ["Close", "Next"],
        },
        {
            image: "onboading-2.svg",
            header: "Climb up the leaderboard with Lympid Community quests",
            text: "Complete one-off and ongoing Lympid Community quests and pull yourself up the $LYP leaderboard.",
            text2: 'Start with "Meet Lympid" quest and get to know us, from then on you will have the opportunity to earn daily points with Telegram and Twitter activity, as well as, RWA special missions.',
            buttons: ["Previous", "Next"],
        },
        {
            image: "onboading-3.svg",
            header: "Get to the top by referring friends to join in",
            text: "Click on referrals and find your referral link",
            text2: "Invite your friends to join Lympid Community and earn 16% of their total points. From then on you will also get 8% of the total points of all indirect referrals.",
            buttons: ["Previous", "Next"],
        },
        {
            image: "onboading-4.jpg",
            header: "Claim your $LYP Real Drop",
            text: "The higher your $LYP leaderboard rank, the bigger the real drop you'll receive. You will be able to claim your real drop after TGE.",
            buttons: ["Previous", "Get Started"],
        },
    ];

    const handleButtonClick = (buttonLabel) => {
        if (
            buttonLabel === "Next" &&
            tutorialIndex < tutorialStates.length - 1
        ) {
            setTutorialIndex(tutorialIndex + 1);
        } else if (buttonLabel === "Previous" && tutorialIndex > 0) {
            setTutorialIndex(tutorialIndex - 1);
        } else if (buttonLabel === "Close") {
            setTutorialOpen(false);
            setTutorialIndex(0);
        } else if (buttonLabel === "Get Started") {
            setTutorialOpen(false);
            setTutorialIndex(0);
        }
    };

    function getLeaderboard() {
        axios
            .get("/api/leaderboard", { withCredentials: true })
            .then((response) => {
                setLeaderboard(response.data.leaderboard);
            })
            .catch((error) => {
                console.error("Error fetching leaderboard:", error);
            });
    }

    useEffect(() => {
        getLeaderboard();
        // Preloading tutorial images
        tutorialStates.forEach((state) => {
            const img = new Image();
            img.src = `/build/img/${state.image}`;
        });
    }, []);

    useEffect(() => {
        if (user && user.recently_created) {
            getLeaderboard();
        }
    }, [user]);

    return (
        <>
            <div className="container-fluid">
                {tutorialOpen && (
                    <>
                        <div
                            className="overlay"
                            onClick={() => {
                                setTutorialOpen(false);
                                setTutorialIndex(0);
                            }}
                        ></div>
                        <div className="tutorial-modal">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h1>
                                        {tutorialStates[tutorialIndex].header}
                                    </h1>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: tutorialStates[
                                                tutorialIndex
                                            ].text,
                                        }}
                                    ></p>
                                </div>
                            </div>
                            <div className="row separator-wrapper">
                                <div className="col-12 text-center">
                                    <div className="separator"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <img
                                        className="onboarding-image"
                                        src={`/build/img/${tutorialStates[tutorialIndex].image}`}
                                        alt="tutorial"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: tutorialStates[
                                                tutorialIndex
                                            ].text2,
                                        }}
                                    ></p>
                                </div>
                            </div>
                            <div className="row navigation-dots">
                                <div className="col-12 d-flex justify-content-center">
                                    {tutorialStates.map((_, index) => (
                                        <button
                                            key={index}
                                            className={`dot ${
                                                tutorialIndex === index
                                                    ? "active"
                                                    : "inactive"
                                            }`}
                                            onClick={() =>
                                                setTutorialIndex(index)
                                            }
                                        ></button>
                                    ))}
                                </div>
                            </div>
                            <div className="row separator-wrapper">
                                <div className="col-12 text-center">
                                    <div className="separator"></div>
                                </div>
                            </div>
                            <div className="row buttons">
                                {tutorialStates[tutorialIndex].buttons.map(
                                    (button, index) => (
                                        <div
                                            key={index}
                                            className="col-6 text-center"
                                        >
                                            <button
                                                className={
                                                    index == 0
                                                        ? "first"
                                                        : "second"
                                                }
                                                onClick={() => {
                                                    handleButtonClick(button);
                                                }}
                                            >
                                                {button}
                                            </button>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </>
                )}
                <Header user={user} open={open} />
                <div className="container hero">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 text-center">
                            <h1>
                                Complete Quests<br></br>Unlock Rewards
                            </h1>
                            <p className="justify-content-center">
                                Join Quests, collect points<br></br>and climb the leaderboard
                            </p>
                            <NavLink to="/studio">
                                <button>Join Now</button>
                            </NavLink>
                            <p
                                onClick={() => {
                                    setTutorialOpen(true);
                                }}
                                className="how-to-get-started"
                            >
                                How to get started
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container your-position">
                    <h2 className="text-center">Your Position</h2>
                    <div className="your-position-wrapper">
                        <div className="row gx-4 d-flex justify-content-center align-items-stretch">
                            <div className="col-12 col-md-6">
                                <div className="card-wrapper current-rank">
                                    <h3>Current Rank</h3>
                                    <p>
                                        #{" "}
                                        {user
                                            ? user.rank.toLocaleString("en-UK")
                                            : 0}
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-3 mt-md-0">
                                <div className="card-wrapper get-referral-points">
                                    <h3>Get Referral Points</h3>
                                    <NavLink to="/referrals">
                                        <img src="/build/img/arrowbutton-right.svg" />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 d-flex justify-content-center align-items-stretch mt-0 mt-md-4">
                            <div className="col-12 col-md-4 mt-3 mt-md-0">
                                <div className="card-wrapper total-points">
                                    <h3>Total Points</h3>
                                    <p>
                                        {user
                                            ? user.total_points.toLocaleString(
                                                  "en-UK"
                                              )
                                            : 0}
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-3 mt-md-0">
                                <div className="card-wrapper community-points">
                                    <h3>Community Points</h3>
                                    <p>
                                        {user
                                            ? user.quests_points.toLocaleString(
                                                  "en-UK"
                                              )
                                            : 0}
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 mt-3 mt-md-0">
                                <div className="card-wrapper referral-points">
                                    <h3>Referral Points</h3>
                                    <p>
                                        {user
                                            ? user.referral_points.toLocaleString(
                                                  "en-UK"
                                              )
                                            : 0}
                                    </p>
                                    <NavLink
                                        to="/referrals"
                                        className={"referrals-button"}
                                    >
                                        <img src="/build/img/arrowbutton-right.svg" />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container leaderboard">
                    <h2 className="text-center">Leaderboard</h2>
                    <p className="subtitle text-center">
                        Participate in quests, move up the leaderboard and
                        unlock rewards
                    </p>
                    <p className="disclaimer text-center">
                        *Updated every 10 minutes
                    </p>
                    <div className="leaderboard-wrapper">
                        <div className="row leaderboard-ranking d-flex">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="text-center position-column"
                                                    scope="col"
                                                >
                                                    #
                                                </th>
                                                <th
                                                    className="text-center"
                                                    scope="col"
                                                >
                                                    Wallet
                                                </th>
                                                <th
                                                    className="text-center"
                                                    scope="col"
                                                >
                                                    Community Points
                                                </th>
                                                <th
                                                    className="text-center"
                                                    scope="col"
                                                >
                                                    Referral Points
                                                </th>
                                                <th
                                                    className="text-center"
                                                    scope="col"
                                                >
                                                    Total Points
                                                </th>
                                            </tr>
                                            <tr className="spacer-row"></tr>
                                        </thead>
                                        <tbody>
                                            {leaderboard.length > 0 &&
                                                leaderboard.map(
                                                    (participant, index) => {
                                                        let className;
                                                        switch (index + 1) {
                                                            case 1:
                                                                className =
                                                                    "gold";
                                                                break;
                                                            case 2:
                                                                className =
                                                                    "silver";
                                                                break;
                                                            case 3:
                                                                className =
                                                                    "bronze";
                                                                break;
                                                            default:
                                                                className = "";
                                                                break;
                                                        }
                                                        if (user) {
                                                            if (
                                                                index + 1 ===
                                                                user.rank
                                                            ) {
                                                                className =
                                                                    "green";
                                                            }
                                                        }
                                                        return (
                                                            <tr
                                                                key={index + 1}
                                                                className={
                                                                    className
                                                                }
                                                            >
                                                                <td className="text-center position-column">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="text-center">
                                                                    <img
                                                                        className="leaderboard-user"
                                                                        src="/build/img/leaderboard-user.svg"
                                                                    />{" "}
                                                                    {
                                                                        participant.wallet_address
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    {participant.quests_points.toLocaleString(
                                                                        "en-UK"
                                                                    )}
                                                                </td>
                                                                <td className="text-center">
                                                                    {participant.referral_points.toLocaleString(
                                                                        "en-UK"
                                                                    )}
                                                                </td>
                                                                <td className="text-center total-points">
                                                                    {participant.total_points.toLocaleString(
                                                                        "en-UK"
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default MainStage;
