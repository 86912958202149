/**
 * Formats an Ethereum address into a more human-readable form by displaying
 * only the first and last few characters.
 * @param {string} address - The Ethereum address to format.
 * @return {string} The formatted Ethereum address.
 */
export const formatEthereumAddress = (address) => {
    if (address.length <= 9) {
        return address; // Return as is if the address is too short.
    }
    const start = address.substring(0, 4).toUpperCase(); // First four characters.
    const end = address.substring(address.length - 4).toUpperCase(); // Last four characters.
    return `${start}...${end}`; // Combine with ellipsis in the middle.
};
