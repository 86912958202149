import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Web3ModalProvider } from "./Web3ModalProvider";
import { Web3Provider } from "./Web3Context";
import MainStageWrapper from "./components/MainStageWrapper";
import QuestStage from "./components/QuestStage";
import ReferralStage from "./components/ReferralStage";
import UserQuest from "./components/UserQuest";
import TelegramQuest from "./components/TelegramQuest";
import axios from "axios";
import * as Sentry from "@sentry/react";

import "../scss/app.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";

axios.defaults.withCredentials = true;

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
    dsn: "https://9ebe429f570a2d6a45bc76e9e25b54e9@o4507363319218176.ingest.de.sentry.io/4507363430498384",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://community.lympid.io"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

if (document.getElementById("app")) {
    const root = ReactDOM.createRoot(document.getElementById("app"));
    root.render(
        <Web3ModalProvider>
            <Web3Provider>
                <Router>
                    <Routes>
                        <Route path="/" element={<MainStageWrapper />} />
                        <Route
                            path="/ref/:refCode"
                            element={<MainStageWrapper />}
                        />
                        <Route path="/studio" element={<QuestStage />} />
                        <Route
                            path="/referrals"
                            element={<ReferralStage />}
                        />
                        <Route
                            path="/quests/telegram"
                            element={<TelegramQuest />}
                        />
                        <Route
                            path="/quests/user"
                            element={<UserQuest />}
                        />
                    </Routes>
                </Router>
            </Web3Provider>
        </Web3ModalProvider>
    );
}
