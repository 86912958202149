import React, { useEffect, useState } from "react";
import { useWeb3 } from "../Web3Context";
import Header from "./Header";
import Footer from "./Footer";
import { formatEthereumAddress } from "../Helpers";
import { NavLink } from "react-router-dom";

const ReferralStage = () => {
    const { user, open } = useWeb3();
    const [copyReferralStatus, setCopyReferralStatus] = useState("default");
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [tutorialIndex, setTutorialIndex] = useState(0);
    const tutorialStates = [
        {
            image: "referral-tutorial.svg",
            header: "No limit referral program",
            text: "Invite your friends to join Lympid Community and earn 16% of their total points. From then on you will also get 8% of the total points of all indirect referrals.",
            buttons: ["Get Started"],
        },
    ];

    const copyReferral = () => {
        const referralLink = `${window.location.origin}/ref/${user.ref_code}`;
        navigator.clipboard
            .writeText(referralLink)
            .then(() => {
                setCopyReferralStatus("copied"); // Update status to 'copied'
                setTimeout(() => setCopyReferralStatus("default"), 2000); // Revert to 'default' after 2 seconds
            })
            .catch((err) => console.error("Failed to copy", err));
    };

    useEffect(() => {
        // Preloading the tutorial image
        const img = new Image();
        img.src = `/build/img/${tutorialStates[0].image}`;
    }, []);

    return (
        <div className="background-container">
            <div className="background" id="bg1"></div>
            <div className="background" id="bg2"></div>
            <div className="background" id="bg3"></div>
            <div className="content container-fluid">
                {tutorialOpen && (
                    <>
                        <div
                            className="overlay"
                            onClick={() => {
                                setTutorialOpen(false);
                            }}
                        ></div>
                        <div className="tutorial-modal">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h1>
                                        {tutorialStates[tutorialIndex].header}
                                    </h1>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: tutorialStates[
                                                tutorialIndex
                                            ].text,
                                        }}
                                    ></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <img
                                        className="onboarding-image"
                                        src={`/build/img/${tutorialStates[tutorialIndex].image}`}
                                        alt="tutorial"
                                    />
                                </div>
                            </div>
                            <div className="row buttons">
                                {tutorialStates[tutorialIndex].buttons.map(
                                    (button, index) => (
                                        <div
                                            key={index}
                                            className="col-12 text-center"
                                        >
                                            <button
                                                className={
                                                    index == 0
                                                        ? "first"
                                                        : "second"
                                                }
                                                onClick={() => {
                                                    setTutorialOpen(false);
                                                }}
                                            >
                                                {button}
                                            </button>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </>
                )}
                <Header user={user} open={open} />
                <div className="container hero">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 text-center">
                            <h1>Referrals</h1>
                            <p className="justify-content-center">
                                Unlock bonus points with every referral you
                                make!
                            </p>
                            <button
                                onClick={() => {
                                    window.open(
                                        "https://zealy.io/cw/lympid/questboard",
                                        "_blank"
                                    );
                                }}
                            >
                                Go to Quests
                            </button>
                            <p
                                onClick={() => {
                                    setTutorialOpen(true);
                                }}
                                className="how-referring-works"
                            >
                                How does referring work?
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container referrals">
                    <div className="referrals-wrapper">
                        <div className="row referrals-header d-flex justify-content-center">
                            <div className="col-12 col-md-4 text-start">
                                <div className="referral-data">
                                    <h3>You referred</h3>
                                    <p>
                                        {user ? user.total_referrals : 0} users
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-8 text-end mt-3 mt-md-0">
                                <div className="referral-link">
                                    {user && (
                                        <>
                                            <input
                                                text="text"
                                                defaultValue={
                                                    "https://" +
                                                    window.location.hostname +
                                                    "/ref/" +
                                                    user.ref_code
                                                }
                                            />
                                            <br></br>
                                            <button
                                                onClick={() => {
                                                    copyReferral();
                                                }}
                                            >
                                                {copyReferralStatus === "copied"
                                                    ? "Copied!"
                                                    : "Copy Link"}{" "}
                                                <img src="/build/img/copy-icon.svg" />
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="referral-instructions">
                                    <h2>Your Referrals</h2>
                                    <p>
                                        <strong>Earn 16%</strong> of your level
                                        1 referrals points.
                                        <br />
                                        <strong>Earn 8%</strong> of their
                                        descendants points.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row referral-stats d-flex justify-content-between align-items-stretch">
                            <div className="col-12 col-md-6">
                                <div className="stat level-1">
                                    <h4 className="stat-title">
                                        Level 1 <span>(16% points)</span>
                                    </h4>
                                    <div className="row stat-points">
                                        <div className="col-6">
                                            <span className="point">
                                                {user
                                                    ? user.referrals_lvl_one.toLocaleString(
                                                          "en-UK"
                                                      )
                                                    : 0}
                                            </span>
                                            <br></br>
                                            <span className="label">
                                                Invitees
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <span className="point">
                                                {user
                                                    ? user.referrals_lvl_one_points.toLocaleString(
                                                          "en-UK"
                                                      )
                                                    : 0}
                                            </span>
                                            <br></br>
                                            <span className="label">
                                                Points
                                            </span>
                                        </div>
                                    </div>
                                    <p className="disclaimer">
                                        <span>16% points</span> from your direct
                                        invitees
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-3 mt-md-0">
                                <div className="stat level-2">
                                    <h4 className="stat-title">
                                        Level 2 <span>(8% points)</span>
                                    </h4>
                                    <div className="row stat-points">
                                        <div className="col-6">
                                            <span className="point">
                                                {user
                                                    ? user.referrals_lvl_two.toLocaleString(
                                                          "en-UK"
                                                      )
                                                    : 0}
                                            </span>
                                            <br></br>
                                            <span className="label">
                                                Invitees
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <span className="point">
                                                {user
                                                    ? user.referrals_lvl_two_points.toLocaleString(
                                                          "en-UK"
                                                      )
                                                    : 0}
                                            </span>
                                            <br></br>
                                            <span className="label">
                                                Points
                                            </span>
                                        </div>
                                    </div>
                                    <p className="disclaimer">
                                        <span>8% points</span> from your
                                        invitees’ invitees
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row referral-disclaimer d-flex justify-content-between align-items-stretch">
                            <div className="col-12">
                                <p>*Updated every 10 minutes</p>
                                <p>
                                    *Your referrals must have participated on
                                    our <NavLink to="/studio">Studio</NavLink>
                                </p>
                                <p>
                                    *Your referrals must have a minimum of{" "}
                                    <span>0.008 ETH</span> on the{" "}
                                    <span>Ethereum</span> blockchain for you to
                                    be awarded points
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReferralStage;
