import React from "react";
import { WagmiProvider } from "wagmi";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { mainnet } from "wagmi/chains";
import { createWeb3Modal } from "@web3modal/wagmi/react";

// Setup queryClient
const queryClient = new QueryClient();

// Create wagmiConfig
let projectId = "";
if (process.env.NODE_ENV !== "development") {
    projectId = "1ac977bb47a0d810499cb3278cc57a1d"; // Replace with your actual projectId
} else {
    projectId = "79db1cd7d4628069fa269c24bd5781ab"; // Replace with your actual projectId
}
const metadata = {
    name: "Lympid",
    description: "Real-world assets (RWA) platform built for premium investment products",
    url: "https://community.lympid.io", // Ensure this matches your domain
    icons: [
        "https://community.lympid.io/build/icon/android-chrome-192x192.png",
    ],
};
const chains = [mainnet];
const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
});

// Create modal
createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true,
    enableOnramp: true,
});

export function Web3ModalProvider({ children }) {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </WagmiProvider>
    );
}
