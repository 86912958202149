import React from "react";
import { useWeb3 } from "../Web3Context";
import Header from "./Header";
import Footer from "./Footer";

const QuestStage = () => {
    const { user, open } = useWeb3();

    return (
        <div className="background-container">
            <div className="background" id="bg1"></div>
            <div className="background" id="bg2"></div>
            <div className="background" id="bg3"></div>
            <div className="content container-fluid">
                <Header user={user} open={open} />
                <div className="container hero">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-12 text-center">
                            <h1>Lympid Studio</h1>
                            <p className="justify-content-center">
                                Earn points and climb the leaderboard
                            </p>
                            <button
                                onClick={() => {
                                    window.open(
                                        "https://zealy.io/cw/lympid/questboard",
                                        "_blank"
                                    );
                                }}
                            >
                                Go to Quests
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container quests-cards">
                    <div className="quests-wrapper">
                        <div className="row gx-4 d-flex justify-content-center align-items-stretch">
                            <div className="col-12 col-md-6">
                                <div className="card-wrapper onboarding">
                                    <h2>Meet Lympid</h2>
                                    <p>
                                        Get to know Lympid and collect points
                                        that will grant you the first
                                        $LYP points.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-4 mt-md-0">
                                <div className="card-wrapper daily">
                                    <h2>Community</h2>
                                    <p>
                                        Share your Lympid experience and score
                                        extra points.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 gx-4 d-flex justify-content-center align-items-stretch">
                            <div className="col-12 col-md-6">
                                <div className="card-wrapper rolex">
                                    <h2>Assemble a Rolex</h2>
                                    <p>
                                        With the RWA onchain mission you will be
                                        able to assemble a Rolex Daytona
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-4 mt-md-0">
                                <div className="card-wrapper doriano">
                                    <h2>Get to know Doriano</h2>
                                    <p>
                                        Learn about Doriano, a real horse
                                        galloping onchain. Earn points as you
                                        go.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 gx-4 d-flex justify-content-center align-items-stretch">
                            <div className="col-12">
                                <div className="card-wrapper special">
                                    <h2>Daily Missions</h2>
                                    <p>
                                        Keep tuned for special missions that
                                        will appear along the way. Don't miss
                                        the chance to earn even more points.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default QuestStage;
