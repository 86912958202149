import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useWeb3 } from "../Web3Context";
import Header from "./Header";
import axios from "axios";

const UserQuest = () => {
    const { user, open } = useWeb3();
    const emailInput = useRef(null);
    const [hasError, setHasError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    function handleSubmit() {
        if (!user || !user.wallet_address || !user.token) {
            console.error("User not defined or missing token.");
            return;
        }

        setIsSubmitting(true); // Start the submission process
        const headers = {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        };

        axios
            .post(
                `/api/user/set-lympid-email`,
                { email: emailInput.current.value },
                { withCredentials: true, headers: headers }
            )
            .then((response) => {
                setHasError(false);
                setSuccess(true); // Assume success should handle the success state
            })
            .catch((error) => {
                console.error("Error:", error);
                setHasError(true);
            })
            .finally(() => {
                setIsSubmitting(false); // Reset submission status regardless of the outcome
            });
    }

    useEffect(() => {
        if (user) {
            if (user.lympid_email) {
                setSuccess(true);
            }
        }
    }, [user]);

    return (
        <div className="content container-fluid">
            <Header user={user} open={open} />
            <div className="container user-quest">
                <div className="user-quest-wrapper">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 text-center">
                            {user ? (
                                <>
                                    {!success && (
                                        <>
                                            <h1>
                                                Enter your Lympid email address
                                            </h1>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12">
                                                    <input
                                                        ref={emailInput}
                                                        className="email-address"
                                                        type="email"
                                                        placeholder={
                                                            user
                                                                ? user.lympid_email
                                                                : "Email Address"
                                                        }
                                                    />
                                                    <button
                                                        className="submit"
                                                        onClick={handleSubmit}
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting
                                                            ? "Submitting..."
                                                            : "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {hasError && (
                                        <p className="error">
                                            Email does not exist, please try
                                            again
                                        </p>
                                    )}
                                    {success && (
                                        <p className="success">
                                            Go back to the quest to{" "}
                                            <NavLink to="https://quests.lympid.io/quest/Meet_Lympid">
                                                claim your points!
                                            </NavLink>
                                        </p>
                                    )}
                                </>
                            ) : (
                                <button
                                    className="wallet-button nml"
                                    onClick={() => {
                                        open();
                                    }}
                                >
                                    Connect Wallet
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserQuest;
