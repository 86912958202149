import React from "react";
import { useParams } from "react-router-dom";
import { Web3Provider } from "../Web3Context";
import MainStage from "./MainStage";

const MainStageWrapper = () => {
    const { refCode } = useParams();

    return (
        <Web3Provider refCode={refCode}>
            <MainStage />
        </Web3Provider>
    );
};

export default MainStageWrapper;
