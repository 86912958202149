import React, { useEffect, useRef, useState } from "react";
import { useWeb3 } from "../Web3Context";
import { NavLink } from "react-router-dom";
import Header from "./Header";
import axios from "axios";

const TelegramQuest = () => {
    const { user, open } = useWeb3();
    const [telegramHandle, setTelegramHandle] = useState("");
    const [hasError, setHasError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    function handleSubmit() {
        if (!user || !user.wallet_address || !user.token) {
            console.error("User not defined or missing token.");
            return;
        }

        setIsSubmitting(true);
        const headers = {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        };

        axios
            .post(
                `/api/user/set-telegram-handle`,
                { telegram_handle: telegramHandle },
                { withCredentials: true, headers: headers }
            )
            .then((response) => {
                setHasError(false);
                setSuccess(true);
            })
            .catch((error) => {
                console.error("Error:", error);
                setHasError(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    const handleInputChange = (e) => {
        let value = e.target.value;

        // Add '@' prefix if not present
        if (!value.startsWith("@")) {
            value = "@" + value;
        }

        setTelegramHandle(value);
    };

    useEffect(() => {
        if (user && user.telegram_handle) {
            setSuccess(true);
        }
    }, [user]);

    return (
        <div className="container-fluid">
            <Header user={user} open={open} />
            <div className="container telegram-quest">
                <div className="telegram-quest-wrapper">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 text-center">
                            {user ? (
                                <>
                                    {!success && (
                                        <>
                                            <h1>Enter Telegram handle</h1>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12">
                                                    <input
                                                        className="telegram-handle"
                                                        type="text"
                                                        value={telegramHandle}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        placeholder="@username"
                                                    />
                                                    <button
                                                        className="submit"
                                                        onClick={handleSubmit}
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting
                                                            ? "Submitting..."
                                                            : "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {hasError && (
                                        <p className="error">
                                            Something went wrong, please try
                                            again
                                        </p>
                                    )}
                                    {success && (
                                        <p className="success">
                                            Your Telegram handle has been
                                            submitted successfully!<br></br>
                                            Go back to the{" "}
                                            <NavLink to="https://quests.lympid.io/quest/Daily%20Mission:%20Telegram">
                                                Quest
                                            </NavLink>{" "}
                                            and click{" "}
                                            <strong>Check Eligibility</strong>
                                        </p>
                                    )}
                                </>
                            ) : (
                                <button
                                    className="wallet-button nml"
                                    onClick={() => {
                                        open();
                                    }}
                                >
                                    Connect Wallet
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TelegramQuest;
