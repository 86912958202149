import React, { createContext, useContext, useState, useEffect } from "react";
import { useAccount, useSignMessage, useSwitchChain } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { recoverMessageAddress } from "viem";
import axios from "axios";

const Web3Context = createContext();

export const Web3Provider = ({ children, refCode }) => {
    const { open } = useWeb3Modal();
    const { address, status, chain } = useAccount();
    const { chains, switchChain } = useSwitchChain();
    const { data: signMessageData, signMessage, variables } = useSignMessage();
    const [user, setUser] = useState(null);
    const messageToSign = "Sign this message to login";

    // Persist refCode in localStorage to handle page refresh or re-navigation
    useEffect(() => {
        if (refCode) {
            localStorage.setItem("currentRefCode", refCode);
        }
    }, [refCode]);

    // Retrieve the persisted refCode from localStorage
    const currentRefCode = localStorage.getItem("currentRefCode");

    // Function to handle user login
    const handleLogin = async (address, signedMessage) => {
        try {
            const response = await axios.post(
                "/api/authenticate",
                {
                    wallet_address: address,
                    signed_message: signedMessage,
                    referred_by: currentRefCode,
                },
                { withCredentials: true }
            );
            setUser(response.data.user);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // Effect for handling the authentication flow
    useEffect(() => {
        if (
            status === "connected" &&
            address &&
            !localStorage.getItem(address)
        ) {
            signMessage({ message: messageToSign });
        } else if (
            status === "connected" &&
            address &&
            localStorage.getItem(address)
        ) {
            handleLogin(address, localStorage.getItem(address));
        }
        if (status === "disconnected") {
            localStorage.removeItem(address);
            setUser(null);
        }
    }, [status, address, signMessage]);

    // Effect for ensuring the user is on the correct chain
    useEffect(() => {
        if (chain?.id && chain.id !== 1) {
            switchChain({ chainId: 1 });
        }
    }, [chain, switchChain]);

    // Effect for recovering address from signed message and handling login
    useEffect(() => {
        if (variables?.message && signMessageData) {
            const recoveredAddress = recoverMessageAddress({
                message: variables?.message,
                signature: signMessageData,
            })
                .then((recoveredAddress) => {
                    localStorage.setItem(recoveredAddress, signMessageData);
                    handleLogin(recoveredAddress, signMessageData);
                })
                .catch(console.error);
        }
    }, [signMessageData, variables?.message]);

    return (
        <Web3Context.Provider
            value={{ user, open, signMessage, refCode: currentRefCode }}
        >
            {children}
        </Web3Context.Provider>
    );
};

// Hook to use web3 context easily in other components
export const useWeb3 = () => useContext(Web3Context);
