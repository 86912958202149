import React from "react";

function Footer() {
    return (
        <div className="container-fluid footer">
            <div className="container">
                <div className="footer-wrapper">
                    <div className="row">
                        <div className="col-12 col-md-6 text-center text-md-start">
                            <a
                                href="https://lympid.io"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/build/img/logo-white.svg"
                                    alt="Lympid"
                                    className="logo"
                                />
                            </a>
                            <p>
                                A real-world assets (RWA) platform built for
                                premium investment products
                            </p>
                        </div>
                        <div className="col-12 col-md-6 mt-3 mt-md-0 text-center text-md-start">
                            <div className="social-media-icons">
                                <a
                                    href="https://twitter.com/lympid_official"
                                    target="_blank"
                                >
                                    <img
                                        src="/build/img/x-icon.svg"
                                        alt="Twitter"
                                    />
                                </a>
                                <a
                                    href="https://t.me/lympid_io"
                                    target="_blank"
                                >
                                    <img
                                        src="/build/img/telegram-icon.svg"
                                        alt="Telegram"
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/lympid/"
                                    target="_blank"
                                >
                                    <img
                                        src="/build/img/linkedin-icon.svg"
                                        alt="LinkedIn"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/lympid_io/"
                                    target="_blank"
                                >
                                    <img
                                        src="/build/img/instagram-icon.svg"
                                        alt="Instagram"
                                    />
                                </a>
                                <a
                                    href="https://www.tiktok.com/@lympid_io"
                                    target="_blank"
                                >
                                    <img
                                        src="/build/img/tiktok-icon.svg"
                                        alt="TikTok"
                                    />
                                </a>
                                <a
                                    href="https://discord.gg/MQyKqEcUxH"
                                    target="_blank"
                                >
                                    <img
                                        src="/build/img/discord-icon.svg"
                                        alt="Discord"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
